import React, {useContext} from 'react'
import {Typography, Stack, Box, Button} from '@mui/material'
import {GlobalStateContext} from '../context/GlobalContextProvider'
import Layout from '../layouts'
import {Link} from 'gatsby'

const Index = ({location}) => {
    const {gCompanyEmployee,  gCompanyDevice} = useContext(GlobalStateContext)

    return (
        <Layout location={location}>
            <Typography variant="h2" sx={{m: '15px 24px 0px 24px'}}>
                SESAME Bizへようこそ
            </Typography>
            {gCompanyEmployee.employees.count !== 0 && (
                <Stack direction="row" spacing={2} sx={{p: '18px'}}>
                    <Box
                        component={Link}
                        to={'/devices'}
                        sx={{
                            backgroundColor: 'secondary.light',
                            textAlign: 'left',
                            color: 'inherit',
                            cursor: 'pointer',
                            border: '2px solid transparent',
                            p: '20px',
                            '&:hover': {
                                border: '2px solid #28aeb1',
                            },
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: '60px',
                                fontWeight: 'bold',
                                transform: 'translateY(-20px)',
                            }}
                        >
                            {gCompanyDevice.devices.count}
                            <span style={{fontSize: '20px', fontWeight: 'normal'}}>台</span>
                        </Typography>
                        <Typography>現在のデバイス数</Typography>
                    </Box>
                    <Box
                        component={Link}
                        to={'/employees'}
                        sx={{
                            backgroundColor: 'secondary.light',
                            textAlign: 'left',
                            color: 'inherit',
                            cursor: 'pointer',
                            border: '2px solid transparent',
                            p: '20px',
                            '&:hover': {
                                border: '2px solid #28aeb1',
                            },
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: '60px',
                                fontWeight: 'bold',
                                transform: 'translateY(-20px)',
                            }}
                        >
                            {gCompanyEmployee.employees.count}
                            <span style={{fontSize: '20px', fontWeight: 'normal'}}>人</span>
                        </Typography>
                        <Typography>現在のユーザー数</Typography>
                    </Box>


                </Stack>
            )}
        </Layout>
    )
}

export default Index
